import { renderList as _renderList, Fragment as _Fragment, openBlock as _openBlock, createElementBlock as _createElementBlock, createCommentVNode as _createCommentVNode, toDisplayString as _toDisplayString, createElementVNode as _createElementVNode, pushScopeId as _pushScopeId, popScopeId as _popScopeId } from "vue"

const _withScopeId = n => (_pushScopeId("data-v-5bb04546"),n=n(),_popScopeId(),n)
const _hoisted_1 = { class: "locale-changer" }
const _hoisted_2 = { key: 0 }
const _hoisted_3 = ["onClick"]

export function render(_ctx: any,_cache: any,$props: any,$setup: any,$data: any,$options: any) {
  return (_openBlock(), _createElementBlock("div", _hoisted_1, [
    _createElementVNode("section", null, [
      (_openBlock(true), _createElementBlock(_Fragment, null, _renderList(_ctx.$i18n.availableLocales, (locale, index) => {
        return (_openBlock(), _createElementBlock("span", {
          key: `locale-${locale}`
        }, [
          (index !== 0)
            ? (_openBlock(), _createElementBlock("span", _hoisted_2, "/"))
            : _createCommentVNode("", true),
          _createElementVNode("button", {
            onClick: ($event: any) => (_ctx.$i18n.locale = locale)
          }, _toDisplayString(locale), 9, _hoisted_3)
        ]))
      }), 128))
    ])
  ]))
}