import { createElementVNode as _createElementVNode, openBlock as _openBlock, createElementBlock as _createElementBlock } from "vue"

const _hoisted_1 = ["innerHTML"]
const _hoisted_2 = ["innerHTML"]
const _hoisted_3 = ["innerHTML"]
const _hoisted_4 = ["innerHTML"]

export function render(_ctx: any,_cache: any,$props: any,$setup: any,$data: any,$options: any) {
  return (_openBlock(), _createElementBlock("section", null, [
    _createElementVNode("p", {
      innerHTML: _ctx.$t('rsvp.hotel.pricing')
    }, null, 8, _hoisted_1),
    _createElementVNode("p", {
      innerHTML: _ctx.$t('rsvp.hotel.booking')
    }, null, 8, _hoisted_2),
    _createElementVNode("p", {
      innerHTML: _ctx.$t('rsvp.hotel.date')
    }, null, 8, _hoisted_3),
    _createElementVNode("p", {
      innerHTML: _ctx.$t('rsvp.hotel.questions')
    }, null, 8, _hoisted_4)
  ]))
}