
import { defineComponent } from "vue";
import LocaleChanger from "@/components/LocaleChanger.vue";
import ThemeChanger from "@/components/ThemeChanger.vue";

export default defineComponent({
  name: "Header",
  components: { LocaleChanger, ThemeChanger },
  props: {
    showThemeChanger: {
      type: Boolean,
      default: true,
    },
  },
});
