
import { defineComponent } from "vue";

export default defineComponent({
  name: "ThemeChanger",
  props: {},
  data() {
    return {
      userTheme: localStorage.getItem("user-theme") ?? "light-theme",
    };
  },
  methods: {
    toggleTheme() {
      const activeTheme = localStorage.getItem("user-theme");
      if (activeTheme === "light-theme") {
        this.setTheme("dark-theme");
      } else if (activeTheme === "dark-theme") {
        this.setTheme("light-theme");
      }
    },
    setTheme(theme: string) {
      localStorage.setItem("user-theme", theme);
      this.userTheme = theme;
      document.documentElement.className = theme;
    },
  },
  mounted() {
    const initUserTheme = this.userTheme;
    this.setTheme(initUserTheme);
  },
});
