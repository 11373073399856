import { createI18n } from "vue-i18n";

const messages = {
  no: {
    subject: "deg | dere",
    object: "Du | Dere",
    location: "Voksenåsen hotell",
    saveTheDate: `<p class="intro">
        Hei! Vi gifter oss lørdag den 19. februar 2022 på
        <a
          href="https://voksenaasen.no/"
          target="_blank"
          rel="noreferrer noopener"
          >Voksenåsen hotell</a
        >
        i Oslo. Invitasjon med flere detaljer kommer i nær fremtid, da vil det
        også komme informasjon om booking av rom for de som ønsker det. Vi
        gleder oss, og håper du har muligheten til å komme.
      </p>
      <h2>Spørsmål?</h2>
      <p>
        Kontakt oss på <a href="tel:">+47 916 93 542</a> (Annie) <br />
        eller <a href="tel:">+47 915 12 756</a> (Nils).
      </p>`,
    rsvp: {
      invitationTab: "Invitasjon",
      invitation: {
        welcome: "Kjære {invitedParty}",
        intro: `Vi har gleden av å invitere {subject} til å feire bryllupet vårt<br/>lørdag den 19. februar 2022.`,
        ceremony:
          "Vielsen vil være i Oslo rådhus med oppmøte klokken 13.00. Deretter blir det mottakelse fra klokken 15.00 med aperitiff, påfølgende middag og fest på ",
        noCeremony:
          "Vielsen vil være i Oslo rådhus hvor det er begrenset med plasser. Vi inviterer {subject} derfor til mottakelse fra klokken 15.00 med aperitiff, påfølgende middag og fest på ",
        dressCode: "Antrekk: Dress/kjole/bunad",
        toastmaster: `Ønsker du å holde tale kan du gi beskjed til toastmaster<br/> Ulrik på <a href="mailto:ulrik.aasen{'@'}hotmail.com">ulrik.aasen{'@'}hotmail.com</a>`,
        rsvpDate: "Svar ønskes innen 15. januar via ",
        rsvpLink: " dette skjemaet",
        signed: `Hjertelig hilsen <br/> Annie og Nils <br/> ♥`,
      },
      formTab: "Svar",
      form: {
        answered: "{object} har allerede svart på invitasjonen.",
        placeholder: "Skriv inn",
        invitees: "Hvem kommer?",
        decline: "Vi kan dessverre ikke komme",
        invitee: "Har du muligheten til å komme?",
        email: "Hvilken e-postadresse kan vi kontakte {subject} på?",
        allergies: "Eventuelle allergier eller matpreferanser:",
        submit: "Send inn svar",
        success: "Takk for ditt svar!",
      },
      hotelTab: "Hotell",
      hotel: {
        pricing: `Voksenåsen hotell tilbyr gunstige overnattingspriser for gjester i bryllupet. 880 kr per natt i enkeltrom, og 1190 kr per natt i dobbeltrom, inkludert frokost <i>(forbehold om prisendringer i 2022)</i>.`,
        booking: `For å booke, send mail til <a href="mailto:booking{'@'}voksenaasen.no">booking{'@'}voksenaasen.no</a>, eller ring <a href="tel:+4722811500"><nobr>+47 22 81 15 00</nobr></a> og referer til dato og bryllupet vårt.<br/>Hotellet har feriestengt frem til 15. januar og vil ikke svare på mail eller telefon i denne perioden.`,
        date: "Vi ber om at dere som ønsker å overnatte booker rom innen 20. januar da vi har holdt av rom til gjester. Reservasjonen kan kanselleres inntil dagen før ved eventuelle endringer.",
        questions:
          "Kontakt oss ved spørsmål eller dersom dere trenger hjelp til dette.",
      },
      covidTab: "Korona",
      covid: {
        info: `Vi følger selvfølgelig nasjonale og regionale råd og regler for smittevern.`,
      },
      techSupport: `Har du problemer med nettsiden? <br />
          Kontakt Nils på <a href="tel:+479152756">+47 915 12 756</a> for tech
          support.`,
    },
  },
  fr: {
    subject: "te | vous",
    object: "Tu a| Vous avez",
    location: "l'hôtel Voksenåsen",
    saveTheDate: `<p class="intro">
        Salut! Nous nous marions le samedi 19 février 2022 à
        <a
          href="https://voksenaasen.no/"
          target="_blank"
          rel="noreferrer noopener"
          >l'hôtel Voksenåsen</a
        >
        à Oslo. Une invitation avec plus de détails viendra bientôt, 
        avec également des informations sur la réservation de chambre pour ceux qui le souhaitent. 
        On se réjouit et on espère que vous aurez l'occasion de venir. 
      </p>
      <h2>Questions?</h2>
      <p>
        Contactez nous au <a href="tel:+4791693542">+47 916 93 542</a> (Annie) <br />
        ou <a href="tel:+479152756">+47 915 12 756</a> (Nils).
      </p>`,
    rsvp: {
      invitationTab: "Invitation",
      invitation: {
        welcome: "Chers {invitedParty}",
        intro: `Nous avons le plaisir de {subject} inviter à fêter notre mariage<br/>le samedi 19 février 2022.`,
        ceremony:
          "La cérémonie aura lieu à l'hôtel de ville d'Oslo et commencera à 13h00. Ensuite, il y aura une réception à partir de 15h00 avec un apéritif, un dîner, suivi d'une fête à ",
        noCeremony:
          "La cérémonie aura lieu à l'hôtel de ville d'Oslo où le nombre de places est limité. Nous vous invitons donc {subject} à une réception à partir de 15h00 avec un apéritif, un dîner, suivi d'une fête à  ",
        dressCode: "Tenue habillée: complet/robe/costume national.",
        toastmaster: `Si vous souhaitez faire un discours, vous pouvez informer Lea sur <a href="mailto:leaherde{'@'}live.com"> leaherde{'@'}live.com </a> qui prendra contact avec le maître de cérémonie<br/> Ulrik Aasen.`,
        rsvpDate: "Réponse souhaitée avant le 15 janvier à l'aide de ",
        rsvpLink: "ce schéma",
        signed: `Amicales salutations <br/> Annie og Nils <br/> ♥`,
      },
      formTab: "Réponse",
      form: {
        answered: "{object} déjà répondu à l'invitation.",
        placeholder: "Écrivez ici",
        invitees: "Qui vient ?",
        decline: "Nous ne pouvons malheureusement pas venir",
        invitee: "Avez-vous la possibilité de venir ?",
        email: "À quelle adresse e-mail pouvons-nous {subject} contacter ?",
        allergies: "Allergies ou préférences alimentaires:",
        submit: "Envoyer",
        success: "Merci !",
      },
      hotelTab: "Hôtel",
      hotel: {
        pricing: `L'hôtel Voksenåsen propose des tarifs avantageux pour les invités de notre mariage: 880 NOK par nuit en chambre simple et 1190 NOK par nuit en chambre double, le petit déjeuner est inclus. <i>(sous réserve de modifications des prix en 2022).</i>.`,
        booking: `Pour réserver, envoyez un e-mail à <a href="mailto:booking{'@'}voksenaasen.no">booking{'@'}voksenaasen.no</a>, ou appelez <a href="tel:+4722811500"><nobr>+47 22 81 15 00</nobr></a> et faites référence à la date et à notre mariage.<br/>L'hôtel est fermé jusqu'au 15 janvier et ne répondra pas aux e-mails ou au téléphone durant cette période.`,
        date: "Nous avons préreservé des chambres pour les invités. Nous demandons cependant à ceux qui souhaitent dormir à l'hôtel, de réserver leur chambre avant le 31 janvier 2022. En cas de changement, la réservation peut être annulée jusqu'à la veille.",
        questions:
          "N'hésitez pas à nous contacter si vous avez des questions, ou si vous avez besoin d'aide concernant les réservations.",
      },
      covidTab: "Covid",
      covid: {
        info: `Nous suivons naturellement les conseils nationaux et régionaux ainsi que les règles et restrictions pour limiter les risques de contaminations.`,
      },
      techSupport: `Des problèmes avec le site internet ?  <br />
            Contactez Nils au <a href="tel:+479152756">+47 915 12 756</a> pour support technique.`,
    },
  },
};

const translation = createI18n({
  locale: navigator.language,
  fallbackLocale: "no",
  messages,
});

export default translation;
