
import { defineComponent, PropType } from "vue";
import { RSVPStore } from "@/types";

export default defineComponent({
  name: "Invitation",
  props: {
    store: {
      type: Object as PropType<RSVPStore>,
      required: true,
    },
  },
  computed: {
    invitedParty(): string {
      const invitees = this.store.state.rsvp.invitees;
      return invitees.length > 1 ? invitees.join(" & ") : invitees[0];
    },
    subject(): string {
      const length = this.store.state.rsvp.invitees.length;
      return this.$tc("subject", length);
    },
  },
});
