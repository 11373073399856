
import { defineComponent, PropType } from "vue";
import {
  NForm,
  NFormItem,
  NInput,
  NCheckboxGroup,
  NCheckbox,
  NRadioGroup,
  NRadio,
  NButton,
  NAlert,
} from "naive-ui";
import { postRSVP } from "@/services/api";
import { RSVPStore } from "@/types";

export default defineComponent({
  name: "Form",
  components: {
    NForm,
    NFormItem,
    NInput,
    NCheckboxGroup,
    NCheckbox,
    NRadioGroup,
    NRadio,
    NButton,
    NAlert,
  },
  props: {
    store: {
      type: Object as PropType<RSVPStore>,
      required: true,
    },
  },
  data() {
    return {
      formData: this.store.state.rsvp,
      successfullyPosted: false,
    };
  },
  computed: {
    isMultiple(): boolean {
      return this.store.state.rsvp.invitees.length > 1;
    },
    object(): string {
      const length: number = this.store.state.rsvp.invitees.length;
      return this.$tc("object", length);
    },
    subject(): string {
      const length = this.store.state.rsvp.invitees.length;
      return this.$tc("subject", length);
    },
  },
  methods: {
    sendForm() {
      return postRSVP({ ...this.formData, answered: true }).then(() => {
        this.store.setAnswered(true);
        this.successfullyPosted = true;
      });
    },
    handleRadioChange(e: Event) {
      const value = (e.target as HTMLInputElement).value;
      if (value == "yes") {
        this.formData.participants = this.store.state.rsvp.invitees;
      } else {
        this.formData.participants = [];
      }
    },
  },
});
