import { resolveComponent as _resolveComponent, openBlock as _openBlock, createBlock as _createBlock, createCommentVNode as _createCommentVNode, createElementVNode as _createElementVNode, createVNode as _createVNode, createElementBlock as _createElementBlock } from "vue"

export function render(_ctx: any,_cache: any,$props: any,$setup: any,$data: any,$options: any) {
  const _component_ThemeChanger = _resolveComponent("ThemeChanger")!
  const _component_LocaleChanger = _resolveComponent("LocaleChanger")!

  return (_openBlock(), _createElementBlock("header", null, [
    _createElementVNode("div", null, [
      (_ctx.showThemeChanger)
        ? (_openBlock(), _createBlock(_component_ThemeChanger, { key: 0 }))
        : _createCommentVNode("", true)
    ]),
    _createVNode(_component_LocaleChanger)
  ]))
}