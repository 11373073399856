import { createRouter, createWebHistory, RouteRecordRaw } from "vue-router";
import SaveTheDate from "@/views/SaveTheDate.vue";
import Form from "@/components/rsvp/Form.vue";
import Hotel from "@/components/rsvp/Hotel.vue";
import Covid from "@/components/rsvp/Covid.vue";
import Invitation from "@/components/rsvp/Invitation.vue";

const routes: Array<RouteRecordRaw> = [
  {
    path: "/",
    redirect: "/savethedate",
  },
  {
    path: "/savethedate",
    name: "SaveTheDate",
    component: SaveTheDate,
  },
  {
    path: "/rsvp/:id",
    name: "RSVP",
    // route level code-splitting
    // this generates a separate chunk (about.[hash].js) for this route
    // which is lazy-loaded when the route is visited.
    component: () =>
      import(/* webpackChunkName: "about" */ "../views/RSVP.vue"),
    children: [
      { path: "", name: "invitation", component: Invitation },
      {
        path: "answer",
        name: "answer",
        component: Form,
      },
      { path: "hotel", name: "hotel", component: Hotel },
      { path: "covid", name: "covid", component: Covid, props: true },
    ],
  },
];

const router = createRouter({
  history: createWebHistory(process.env.BASE_URL),
  routes,
});

export default router;
