<template>
  <section>
    <p v-html="$t('rsvp.covid.info')" />
  </section>
</template>

<script>
export default {
  name: "Covid",
  props: { store: { type: Object, required: false } },
};
</script>

<style scoped></style>
