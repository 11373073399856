import { RSVP } from "@/types";

const headers = {
  "Access-Control-Allow-Origin": "origin-list",
  "content-type": "application/json",
  "allow-origin": "*",
};

export const fetchParty = (id: string) => {
  return fetch(`/api/rsvp/${id}`, {
    method: "get",
    headers,
  })
    .then((res) => {
      // a non-200 response code
      if (!res.ok) {
        // create error instance with HTTP status text
        throw new Error(res.statusText);
      }

      return res.json();
    })
    .then((json) => {
      // set the response data
      return json;
    })
    .catch((err) => {
      console.error(err);
    });
};

export const postRSVP = (data: RSVP) => {
  return fetch("/api/rsvp", {
    method: "post",
    headers,
    body: JSON.stringify(data),
  })
    .then((res) => {
      // a non-200 response code
      if (!res.ok) {
        // create error instance with HTTP status text
        throw new Error(res.statusText);
      }

      return res.json();
    })
    .then((json) => {
      // set the response data
      console.log(json.data);
    })
    .catch((err) => {
      console.error(err);
    });
};
