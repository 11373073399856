import { resolveComponent as _resolveComponent, createVNode as _createVNode, createElementVNode as _createElementVNode, Fragment as _Fragment, openBlock as _openBlock, createElementBlock as _createElementBlock } from "vue"

const _hoisted_1 = { class: "home" }
const _hoisted_2 = ["innerHTML"]

export function render(_ctx: any,_cache: any,$props: any,$setup: any,$data: any,$options: any) {
  const _component_Header = _resolveComponent("Header")!
  const _component_HeartShape = _resolveComponent("HeartShape")!

  return (_openBlock(), _createElementBlock(_Fragment, null, [
    _createVNode(_component_Header),
    _createElementVNode("div", _hoisted_1, [
      _createVNode(_component_HeartShape),
      _createElementVNode("section", {
        innerHTML: _ctx.$t('saveTheDate')
      }, null, 8, _hoisted_2)
    ])
  ], 64))
}